import { User } from 'types'

export const trackReferralVisited = (data, loggedUser: User) => {
  return {
    referral_link_views: data.share_clicks_count,
    referral_signups: data.share_signups_count,
    referral_subscribers: loggedUser.invitedNumSubscribers ?? 0,
    available_credits: (data.balance / 100) * -1,
    earned_credits: (loggedUser.invitedNumSubscribers ?? 0) * 10,
  }
}
